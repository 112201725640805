

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');

:root{
  

    /************ Original Color Pallette **************/

    --primary-white: #ffffff;
    --primary-light-grey: #f4f4f4;
    --primary-grey: #ccd1d9;
    --primary-dark-grey: #666666;
    --dark-mode-green: #f9ab00;
    --primary-dark: rgb(52, 58, 64);
    --primary-red: #ef4035;
    --primary-hover-red: #f33501;
    --primary-black: #000000;
    --primary-transparent-black: rgba(0,0,0,0.7);
    --primary-green: green;
    --primary-project-white: #f9f9f9;
  
    /************ Dark Mode Color Pallette **************/
  
    --background-black: #121212;
    --navbar-dark-grey: #181818;
    --body-light-grey: #404040;
    --body-dark-grey: #282828;
    --primary-text-white: #ffffff;
    --secondary-text-white: #B3B3B3;
  
  
    --dark-mode-red: #CF6679;
    --dark-mode-blue: #3700B3;
    --dark-mode-light-blue: #6200EE;
    --dark-mode-purple: #BB86FC;
    --dark-mode-green: #018786;
    --dark-mode-turquoise: #03DAC6;
  
    --dark-mode-extended-yellow: #FF991F;
    --dark-mode-extended-blue: #00A3BF;
    --dark-mode-extended-blue-light: #B3F5FF;

}

body{
  font-family: 'Roboto Condensed', sans-serif !important;
}

h1, h2 {
	font-family: 'Courier New', monospace;
	font-weight: 700;
  text-transform: lowercase !important;
}



/*********NAVBAR********* */
nav{
  z-index: 2;
  
  /* background: transparent; */
}

nav h2{
  color: var(--primary-white);
}

/* MENU LINKS */
nav a.nav-link {
  
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: .75rem;
  margin-left: .75rem;
  color: var(--primary-white) !important;
  border-top: .1875rem solid var(--primary-white);

}

.navbar-nav > li > a.active, .navbar-nav > li > a:focus {
  border-top: .1875rem solid var(--primary-black);
}

.navbar-nav > li > a:hover {
  border-top: .1875rem solid var(--primary-dark);
}

/********* NAV BAR RESUME BUTTON *********/

.navbar-button{
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  border-radius: 0;
  padding: .625rem .75rem .75rem .75rem;
  color: var(--primary-white);
  margin: .5rem;
  
}

.navbar-button:hover{
  text-decoration: none;
  background-color: var(--primary-hover-red);
  color: var(--primary-white);
  transition: .2s ease-in-out;
}


/*********HEADER**********/

.header-wrapper{
  position: relative;
  background: url(myheader.JPG) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.main-info{
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Roboto Condensed', sans-serif;
  z-index: 1;
}

.main-info h1{
  color: var(--primary-white);
  font-size: 120px;
  /* text-transform: uppercase; */
}



/*******TYPED TEXT****** */

.typed-text{
  font-size: 2rem;
  color: var(--primary-white);
}
@media(max-width: 768px){
  .main-info h1{
    font-size: 3rem !important;
  }
  .typed-text{
    font-size: 1.3rem;
  }
}

/******HEADER MAIN BUTTON******/

.btn-main-offer{
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  border-radius: 0;
  padding: .625rem .75rem .75rem .75rem;
  color: var(--primary-white);
  margin: 2rem;
  
}

.btn-main-offer:hover{
  text-decoration: none;
  background-color: var(--primary-hover-red);
  color: var(--primary-white);
  transition: .2s ease-in-out;
}

/******PARTICLES***** */

.tsparticles-canvas-el{
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

@media(max-width: 768px){
  .particles-canvas{
    display: none;
  }
}

/********ABOUT ME*********/

.photo-wrap{
  display: flex;
  justify-content: center;
  align-items: center;

}

.profile-img{
  width: 16rem;
  border-radius: 50%;
  padding: .3rem;
  /* border: 1px solid var(--primary-red); */
}

.about-heading{
  color: var();
  text-transform: uppercase;
}

@media(max-width: 468px){
  .profile-img{
    width: 10rem;
  }
  .about-heading{
    text-align: center;
  }
}

/********************************* SKILLS *********************************/

.skills {
  background-size: cover;
  background-color: var(--primary-white);
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 1rem;
  margin-bottom: 0;
  width: 100%;
}

.skills h1 {
  color: var(--primary-black);
  padding: 1rem 0;
  text-transform: uppercase;
}

.skills-content {
  text-align: center;
  max-width: 18rem;
  margin: 0 auto 2rem auto;
  background: var(--primary-black)
}


.myCarousel {
  color: var(--primary-white);
  background: transparent;
  margin-top: -6%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 55%;
  padding-left: 5%;
  padding-right: 5%;
  height: 9rem;
}

.myCarousel h3 {
  color: var(--primary-white);
  font-weight: 900;
  text-transform: uppercase;
}


.myCarousel p {
  color: var(--primary-grey);
} 


.carousel .slide img {
  width: 10rem !important;
  border-radius: 50%;
}

.carousel .slide {
  background: transparent !important;
  height: 100% !important;
  padding-top: 2rem;
}

.carousel svg {
  font-size: 5rem;
}

.carousel-root {
  margin: auto !important;
  margin-top: 3% !important;
}

/********************************* LINKS PAGE *********************************/

.links {
  text-align: center;
  padding-bottom: 0rem;
  background: var(--primary-white);
  padding-top: 0rem;
  background-size: cover;
  background-position: center;


}

.links h1 {
  color: var(--primary-black);
  text-transform: uppercase;
}

.links h3 {
  color: var(--primary-black);
  text-transform: uppercase;
}

.links .circle {
  position: relative;
  margin: 0.375rem auto;
  background: var(--primary-white);
  border: 1px solid var(--primary-black);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.links .icon {
  font-size: 1.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.links .box {
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: transparent;
  transition: 0.3s ease-in-out;
 
 
}

.links .box:hover {
  border-bottom: 0.3125rem solid  var(--primary-black);
  cursor: pointer;
  
}

.links .box:hover .icon {
  color: var(--primary-white);
}

.links .box:hover .circle {
  background: var(--primary-black);
}

.links .box:hover h3 {
  color: var(--primary-black);
  font-weight: bold !important;
}

.links-div {
  padding-top: 4rem;
}

.links a {
  text-decoration: none !important;
  color: inherit;
}

.links a:hover {
  text-decoration: none !important;
  color: inherit;
}


/******** LINK BUTTONS **********/

button.btn-links {
  background: var(--body-dark-grey);
  height: 100px;
  width: 105px;
  font-size: large;
  border: 1px solid var(--dark-mode-turquoise);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: var(--secondary-text-white);
  margin-top: .75rem;
  cursor: pointer;
}

.btn-links:hover{
  text-decoration: none;
  background-color: var(--body-light-grey);
  color: var(--secondary-text-white);
  transition: .2s ease-in-out;
}

/******** LINKS SKILLS ICONS **********/

.skills-icons {
  margin: 3rem;
}


/*********** PROJECTS ******** */

.project-wrapper{
  background: var(--primary-project-white);
  padding-bottom: 4rem;
}



.project-image{
  width: 16rem;
  height: 9rem;
  border: 1px solid var(--primary-project-white);
  padding: 0 0.5rem;
}

.project-image-box{
  position: relative;
  margin: 1rem;
}


/****** OVERFLOW ********/

.overflow{
  position: absolute;
  opacity: 0;
  background: var(--primary-black);
  width: 16rem;
  height: 9rem;
  top: 0;
  border: 1px solid var(--primary-project-white);
  cursor: pointer;
}

.project-image-box:hover .overflow{
  opacity: .2;
  transition: 0.3s ease-in-out;
  
}

.project-icon{
  position: absolute;
  opacity: 0;
  margin-left: -9rem;
  margin-top: 3.6rem;
  color: var(--primary-white);
  font-size: 1.5rem;
}

.project-image-box:hover .project-icon{
  opacity: .7;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}


/* ****** POP UP BOX **************/

.popupbox-wrapper{
  margin-top: 4rem;
  background-color: var(--background-black) !important;
}

.popupbox-titleBar{
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  background-color: var(--body-dark-grey);
  color: var(--primary-white);
}

.popupbox-btn--close{
  top: 4px !important;
}

.popupbox-content{
  padding: .6rem 1rem !important;
  background-color: var(--body-dark-grey);
}

.project-image-popupbox {
  width: 45rem;
  padding: 0.5rem; 
  border: .025rem solid var(--primary-white);
  border-radius: .25rem;
}

.popupbox-titleBar span{
  
  font-size: 1.5rem;
  font-family: 'Courier New', monospace;
	font-weight: 700;
  text-transform: lowercase !important;
}

.popupbox p{
  color: var(--primary-white);
  margin: .5rem;
}

.popup-desc{
  font-size: .88rem;
  width: 45rem;
  margin: .25rem 0;
  margin-bottom: .3rem;
}


.hyper-link {
  cursor: pointer;
  color: var(--primary-white);
  margin: 0 1rem;
  font-size: 1rem;
}

.hyper-link:hover{
  text-decoration: none;
  transition: 0.3s ease-in-out;
  color: var(--primary-white);
}

.popup-icon:hover{
  text-decoration: none;
  transition: 0.3s ease-in-out;
  color: var(--primary-white);
}

.popup-icons {
  display: flex;
  justify-content: center;
}

.popup-icon {
  margin-right: .25rem;
  color: var(--primary-white);
}

.popup-links span{
  line-height: 80%;
}

/**** WIDTH ****/

@media(max-width: 1050px){

  .project-image-popupbox{
    width: 90%;
  }

  .popup-desc{
    width: 100%;
    font-size: .75rem;
  }
}

@media(max-width: 768px){

  .project-image-popupbox{
    width: 100%;
  }

  .popup-desc{
    font-size: .61rem;
    width: 100%;
    margin: .25rem 0;
  }

  .popup-links{
    font-size: .8rem;
  }
}

@media(max-width: 550px){
  .popup-links{
    font-size: .7rem;
  }

}


/**** HEIGHT ****/

@media(max-height: 640px){
  .popupbox-wrapper{
    width: 60%;
  }

  .popupbox-content{
    text-align: center;
  }


  .project-image-popupbox{
    width: 60%;
  }

  .popup-desc {
   width: auto;
  }

  .popup-links{
    font-size: .7rem;
  }
}

@media(max-height: 570px){

  .project-image-popupbox{
    width: 40%;
  }
}

/********************************* CONTACT *********************************/

.contact {
  background: var(--primary-project-white);
  padding: 3rem 0;
  background-size: cover;
  background-position: center;
}

.contact h1 {
  color: var(--primary-black);
  padding: 1.3rem 0;
  
}

.contact p {
  color: var(--primary-black);
  font-size: 1.2rem;
}

/****** INPUTS  ******/

.contact input, .contact textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.1rem solid var(--primary-dark-grey);
  border-radius: 0;
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contact .container {
  padding: 4rem 0;
}

.contact input:focus, .contact textarea:focus {
  background: transparent;
  color: var(--primary-black);
  border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contact textarea {
  height: 12.2rem !important;
}

button.btn-main-offer.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
  border-color: var(--primary-black);
  color: var(--primary-black);
}

button.btn-main-offer.contact-btn:hover{
  text-decoration: none;
  background-color: var(--primary-black);
  color: var(--primary-white);
  transition: .2s ease-in-out;
}

.line {
  position: relative;
  top: -0.8rem;
  width: 0;
  height: .0125rem;
  background: var(--primary-black);
  display: inline-block;
}

input:focus+.line, textarea:focus+.line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: var(--primary-red);
  text-align: left;
}

.success-message {
  font-weight: 900;
  color: var(--primary-black);
}

/****** MEDIA  ******/

@media(max-width: 768px) {
  .contact {
    overflow-x: hidden;
    background-size: 100rem;
    background-position: center;
  }

  .contact .container {
    padding: 0 1rem;
  }

  .contact p {
    padding: 0 0.4rem;
  }
}

/**********FOOTER****** */

.footer {
  color: var(--primary-white);
  background: url(myheader.JPG) no-repeat;
  position: relative;
  background-size: cover;
  background-position: center;
  height: 3rem;
}